<template>
  <div class="price-container" id="price">
    <h1 class="title">{{ content.title }}</h1>
    <p class="des">{{ content.des }}</p>
    <div class="price-list">
      <div class="price-setup" v-for="(item, idx) in content.priceSetup">
        <div style="display: flex; align-items: center; flex-wrap: wrap;">
          <div v-html="item.price"></div>
          <div class="tag">{{ item.tag }}</div>
        </div>
        <ul>
          <li class="des" v-for="line in item.desList">
            {{ line }}
          </li>
        </ul>
        <button class="pay-button" @click="nopay">{{ item.buttonTxt }}</button>
        <p class="tip">{{ item.tip }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {}
    },
  },
  data() {
    return {}
  },
  methods:{
    nopay(){
      alert('The service is temporarily inaccessible')
    }
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 981px) {
  .price-container {
    width: 1020px;
    padding: 200px 50px 0 50px;
    text-align: center;
    margin: 0 auto;
    .title {
      font-size: 36px;
      font-family: Roboto-Medium, Roboto;
      color: rgba(51, 51, 51, 1);
      line-height: 42px;
      text-align: center;
      margin-bottom: 24px;
    }
    .des {
      font-size: 16px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      margin-bottom: 44px;
    }
    &::v-deep .high-bold {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      line-height: 28px;
    }
    &::v-deep .medium-bold {
      font-size: 14px;
      color: #7C7C7C;
      line-height: 18px;
      margin-top: 10px;
    }
    .price-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      text-align: left;
      .price-setup {
        min-width: 500px;
        padding: 32px;
        box-sizing: border-box;
        border: 4px solid #F2F8FF;
        margin: 0 auto;
        .tag {
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          padding: 2px 10px;
          background: #5f6458;
          border-radius: 12px;
          margin-left: 8px;
        }
        ul {
          margin-top: 16px;
          margin-bottom: 24px;
          li {
            margin-bottom: 8px;
          }
        }
        .pay-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 222px;
          height: 48px;
          background: #6b6bc0;
          border-radius: 30px;
          font-size: 20px;
          font-weight: bold;
          line-height: 24px;
          color: #FFFFFF;
          border: none;
          &:hover {
            opacity: 0.8;
          }
        }
        .tip {
          font-size: 14px;
          color: #7C7C7C;
          line-height: 18px;
          margin-top: 10px;
        }
      }
    }
  }
}
@media (max-width: 981px) {
  .price-container {
    width: 100%;
    margin: 1rem auto;
    text-align: center;
    padding: 0.32rem;
    box-sizing: border-box;
    .title {
      font-size: 36px;
      font-family: Roboto-Medium, Roboto;
      color: rgba(51, 51, 51, 1);
      line-height: 42px;
      text-align: center;
      margin-bottom: 24px;
    }
    .des {
      font-size: 16px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      margin-bottom: 44px;
    }
    &::v-deep .high-bold {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      line-height: 28px;
    }
    &::v-deep .medium-bold {
      font-size: 14px;
      color: #7C7C7C;
      line-height: 18px;
      margin-top: 10px;
    }
    .price-list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      text-align: left;
      .price-setup {
        min-width: 100%;
        padding: 32px;
        box-sizing: border-box;
        border: 4px solid #F2F8FF;
        margin: 0 auto;
        .tag {
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          padding: 2px 10px;
          background: #5f6458;
          border-radius: 12px;
          margin-left: 8px;
        }
        ul {
          margin-top: 16px;
          margin-bottom: 24px;
          li {
            margin-bottom: 8px;
          }
        }
        .pay-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 222px;
          height: 48px;
          background: #6b6bc0;
          border-radius: 30px;
          font-size: 20px;
          font-weight: bold;
          line-height: 24px;
          color: #FFFFFF;
          border: none;
          &:hover {
            opacity: 0.8;
          }
        }
        .tip {
          font-size: 14px;
          color: #7C7C7C;
          line-height: 18px;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>